import { GetCardType } from '@/api/common'
const state = {
    cardType: null,
    carLevel:null
}
const mutations = {
    SET_CARDTYPE: (state, cardType) => {
        state.cardType = cardType
    },
    SET_CARDLEVEL: (state, carLevel) => {
        state.carLevel = carLevel
    }
}
const actions = {
    getCardType({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (state.cardType) {
                resolve(state.cardType)
            } else {
                GetCardType().then(res => {
                    const { data } = res
                    data.forEach((item,i) => {
                        if(item.value ==  '无属性'){
                            data.splice(i,1)
                        }else if(item.key == 4){
                            item.color  = '#3b9ffc'
                        }else if(item.key == 1){
                            item.color  = '#f97373'
                        }else if(item.key == 2){
                            item.color  = '#64c935'
                        }else if(item.key == 3){
                            item.color  = '#6358a8'
                        }
                    });
                    
                    commit('SET_CARDTYPE', data)
                    resolve(data)
                }).catch(err => {
                    reject(err)
                })
            }
        })
    },
    getCardLevel({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (state.carLevel) {
                resolve(state.carLevel)
            } else {
                const { GetLevel } = this.$api.common
                GetLevel().then(res => {
                    const { data } = res
                    commit('SET_CARDLEVEL', data)
                    resolve(data)
                }).catch(err => {
                    reject(err)
                })
            }
        })
    }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
