import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', false, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  mutations: {
    updateParam (state, param) {
      let attr, data
      if (param.length > 1) {
        [attr, data] = param
      } else {
        [data] = param
      }
      const keys = Object.keys(data)
      for (const key of keys) {
        if (attr) {
          state[attr][key] = data[key]
        } else {
          state[key] = data[key]
        }
      }
    }
  },
  actions: {
    updateParam ({ commit }, param) {
      commit('updateParam', param)
    }
  },
  modules,
  getters
})
