<template>
    <div>
        <a-spin :spinning="spinning">
            <a-form-model
                :model="registerFrom"
                ref="registerForm"
                :rules="registerRules"
            >
                <!-- 账号 -->
                <a-form-model-item prop="phone">
                    <div class="input">
                        <svg-icon
                            icon-class="icon_acount"
                            class="icon"
                        ></svg-icon>
                        <a-input
                            v-model="registerFrom.phone"
                            placeholder="请输入邮箱或手机号"
                            @keyup.enter="register"
                        />
                    </div>
                </a-form-model-item>
                <!-- 验证码 -->
                <a-form-model-item prop="code">
                    <div class="input">
                        <svg-icon icon-class="icon_ver" class="icon"></svg-icon>
                        <a-input
                            v-model="registerFrom.code"
                            placeholder="请输入验证码"
                            :maxLength="6"
                            @keyup.enter="register"
                        />
                    </div>
                    <a-button
                        class="login_btn ver"
                        @click="sendSMS($event)"
                        :class="!showCodeBtn ? 'not' : ''"
                        :loading="codeLoading"
                    >
                        <span v-if="showCodeBtn">获取验证码</span>
                        <span v-else>{{ count }}s</span>
                    </a-button>
                </a-form-model-item>
                <!-- 密码 -->
                <a-form-model-item prop="password">
                    <div class="input">
                        <svg-icon
                            icon-class="icon_password"
                            class="icon"
                        ></svg-icon>
                        <a-input
                            v-model="registerFrom.password"
                            placeholder="请输入密码"
                            type="password"
                            @keyup.enter="register"
                        />
                    </div>
                </a-form-model-item>
                <!-- 确认密码 -->
                <a-form-model-item prop="confirm">
                    <div class="input">
                        <svg-icon
                            icon-class="icon_password"
                            class="icon"
                        ></svg-icon>
                        <a-input
                            v-model="registerFrom.confirm"
                            placeholder="请再次输入密码！"
                            @keyup.enter="register"
                            type="password"
                        />
                    </div>
                </a-form-model-item>
                <div class="forget-pw">
                    <span class="login_span">
                        <a-checkbox v-model="isReadProvicy"> </a-checkbox>
                        <span style="margin-left: 10px;"></span>
                        <span @click.stop="openPrivady">请阅读并同意《文物保护考试服务平台用户隐私政策》</span>
                    </span>
                </div>
                <a-form-model-item class="login">
                    <a-button class="login_btn" @click="register">
                        立即注册
                    </a-button>
                </a-form-model-item>
                <div class="form-footer">
                    <span class="login_span underline" @click="swicthLogin(0)">
                        已有账号，去登录
                    </span>
                </div>
            </a-form-model>
        </a-spin>
        <Verify :showVerify="showVerify" @success="onSuccess" @fail="onFail" />
        <a-modal v-model="privacyShow" :width="800" title="《文物保护考试服务平台用户隐私政策》" @ok="handleOk">
            <div v-html="privacy"></div>
        </a-modal>
    </div>
</template>
<script>
import { Base64 } from 'js-base64'
import Verify from '@/components/common/Verify'
import { SendSMS } from '@/api/common'
import { Register } from '@/api/login'
export default {
    props:['value','privacy'],
    components: {
        Verify,
    },
    data() {
        let validatePhone = (rule, value, callback) => {
            const regEmail = /^\w+([-+.]\w+)*@@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
            const regMobile =
                /^0?(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/
            if (value === '') {
                callback(new Error('请输入邮箱或手机号'))
            } else if (regEmail.test(value) || regMobile.test(value)) {
                callback()
                return
            } else {
                callback(new Error('请输入正确邮箱或手机号！'))
            }
        }
        let validateConfirm = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码！'))
            } else if (value !== this.registerFrom.password) {
                callback(new Error('两次输入密码不一致！'))
            } else {
                callback()
            }
        }
        return {
            // 注册
            registerFrom: {
                phone: '',
                password: '',
                code: '',
                confirm: '',
            },
            // 验证规则
            registerRules: {
                phone: [
                    {
                        required: true,
                        message: '请输入邮箱或手机号',
                        trigger: 'blur',
                    },
                    { validator: validatePhone, trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                code: [
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur',
                    },
                ],
                confirm: [{ validator: validateConfirm, trigger: 'blur' }],
            },
            // 接口获取的验证码
            code: '',
            // 计时器
            timer: '',
            count: '',
            showCodeBtn: true,
            // loading
            spinning: false,
            // 图形验证
            showVerify: false,
            codeLoading: false,
            privacyShow:false
        }
    },
    computed:{
        isReadProvicy:{
            get(){
                return this.value
            },
            set(newVal){
                this.$emit('input',newVal)
            }
        }
    },
    methods: {
        // 切换登录方式
        swicthLogin(type) {
            this.$emit('change', type)
        },
        onFail() {
            this.$message.error('请点击鼠标左键，滑动至缺口!')
        },
        onSuccess() {
            this.codeLoading = true
            SendSMS(this.registerFrom.phone)
                .then(res => {
                    this.codeLoading = false
                    this.$message.success(res.msg)
                    this.code = res.data
                    let leftTime = 60
                    if (!this.timer) {
                        this.count = leftTime
                        this.showCodeBtn = false
                        // 开启定时器
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= leftTime) {
                                this.count--
                            } else {
                                this.showCodeBtn = true
                                clearInterval(this.timer)
                                this.timer = null
                            }
                        }, 1000)
                    }
                })
                .catch(e => {
                    console.log(e)
                    this.$message.error(e)
                    this.codeLoading = false
                })
            setTimeout(() => {
                this.showVerify = false
            }, 500)
        },
        // 获取验证码
        sendSMS(event) {
            if (!this.showCodeBtn) return
            this.$refs.registerForm.validateField(['phone'], (err, values) => {
                if (!err) {
                    this.onSuccess()
                    // this.showVerify = true
                } else {
                    console.log(err)
                }
            })
        },
        // 注册
        register() {
            if(!this.isReadProvicy)  return  this.$message.info('请阅读并同意《文物保护考试服务平台用户隐私政策》');
            this.$refs.registerForm.validate(valid => {
                if (valid) {
                    this.spinning = true
                    const { phone, password, code } = this.registerFrom
                    const data = Base64.encode(
                        JSON.stringify({ phone, password, code })
                    )
                    Register(data)
                        .then(res => {
                            this.$message.success(res.msg)
                            this.spinning = false
                            this.$emit('change', 0)
                        })
                        .catch(e => {
                            console.log(e)
                            this.$message.error(e)
                            this.spinning = false
                        })
                } else {
                    return false
                }
            })
        },
        openPrivady(){
            this.privacyShow = true
        },
        handleOk(){
            this.isReadProvicy = true
        }
    },
}
</script>
<style scoped lang="less">
@import '~@/assets/css/login.less';
</style>
