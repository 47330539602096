const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  Phone: state => state.user.Phone,
  navActiveId: state => state.user.navActiveId,
  modelType: state => state.model.modelType,
  downloadType: state => state.app.downloadType,
  subjectListNoTree: state => state.model.subjectListNoTree,
  nav: state => state.app.navList,
}

export default getters
