import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/permission' // permission control
import './icons/idnex' // icon
import axios from 'axios'


// # 引入组件库
import ant from 'ant-design-vue'
// # 引入样式表
import 'ant-design-vue/dist/antd.css'
import '@/assets/css/antd-ui.less'
Vue.use(ant);

// MateInfo
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo);
import SlideVerify from 'vue-monoplasty-slide-verify';
// 滑块验证
Vue.use(SlideVerify);

// swiper
import css from 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, { css })
Vue.config.productionTip = false

// v-viewer
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  // 需要配置的属性 注意属性并没有引号
  //   title: false,
  //   toolbar: false
})
import '@/assets/css/common.less'

// tinymce
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
Vue.prototype.$http = axios
Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
