const icon01 = require('@/static/image/info_nav01.png')
const icon02 = require('@/static/image/info_nav02.png')
const icon03 = require('@/static/image/info_nav03.png')

const bg01 = require('@/assets/images/kdsj.jpg')
const bg02 = require('@/assets/images/zsxj.jpg')
const bg03 = require('@/assets/images/xxbj.jpg')
import { GetSubjectList } from '@/api/common'
const state = {
    // 组合模型类型
    modelType: [
        {
            value: 0,
            name: '考试大纲',
            subtitle: '考点+平台辅助例题',
            info: '从模拟试题入手，快速熟悉考试规则，快速熟悉学科考点。',
            icon: icon01,
            bg: bg01,
            class: 'kdsj',
            request: 'GetExamListByModelId',
            ids:[4,3]
        },
        {
            value: 1,
            name: '知识详解',
            subtitle: '考点+知识点',
            info: '从知识详情入手，快速熟悉考试规则，快速熟悉学科考点。',
            icon: icon02,
            bg: bg02,
            class: 'zsxj',
            request: 'GetKnowledgeListByModelId',
            ids:[4,1]
        },
        {
            value: 2,
            name: '资料笔记',
            subtitle: '考点+知识点+试题',
            info: '详细了解整个考试，从考点到拓展知识点例题全方位了解考试并了解文物保护工程。',
            icon: icon03,
            bg: bg03,
            class: 'xxbj',
            request: 'GetNoteListByModelId',
            ids:[2]
        },
    ],
    // 考试科目
    subjectList: [],
    subjectListNoTree: [],
}
const mutations = {
    SET_SUBJECT: (state, subject) => {
        state.subjectList = subject
    },
    SET_SUBJECT_NOTREE: (state, subject) => {
        state.subjectListNoTree = subject
    },
    SET_MODELTYPE: (state,modelType) => {
        modelType.forEach((item, i) => {
            state.modelType[i].name = item.Title || state.modelType.name
            state.modelType[i].subtitle = item.SubTitle || state.modelType.subtitle
            state.modelType[i].info = item.Info || state.modelType.info
            state.modelType[i].icon = item.Image || state.modelType.icon
        })
    }
}
const actions = {
    getSubject({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (state.subjectList.length) {
                resolve(state.subjectList)
            } else {
                GetSubjectList().then(res => {
                    const { data } = res
                    commit('SET_SUBJECT_NOTREE',data)
                    // 循环请求回来的数据生成树状结构
                    let temp = []
                    data.forEach(item => {
                        if (!item.ParentId) {
                            // 第一级
                            temp.push(item)
                            // 第二级
                            temp[temp.length - 1].children = data.filter(i => i.ParentId == temp[temp.length - 1].Id)
                            // 第三级
                            temp[temp.length - 1].children.forEach((subItem) => {
                                subItem.children = data.filter(j => j.ParentId == subItem.Id)
                            })
                        }
                    });
                    commit('SET_SUBJECT', temp)
                    resolve(temp)
                }).catch(err => {
                    reject(err)
                })
            }
        })
    },
    getModelType({ commit, state }) {
        return new Promise((resolve, reject) => {
            GetDataList({columnId:10038}).then(res => {
                const { data } = res
                commit('SET_MODELTYPE', data)
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
