import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import { AsyncGetWxUserInfo } from '@/api/login'
import { message } from 'ant-design-vue'
import { Modal } from 'ant-design-vue';
NProgress.configure({ showSpinner: false }) // NProgress Configuration


router.beforeEach(async (to, from, next) => {
    document.querySelector('meta[name="keywords"]')?.remove()
    document.querySelector('meta[name="description"]')?.remove()
    if (to.path) {
        if (window._hmt) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
        }
    }
    // start progress bar
    NProgress.start()
    // determine whether the user has logged in
    const hasToken = getToken()

    if (hasToken) {
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done()
        } else {
            const hasGetUserInfo = store.getters.userInfo.LoginName
            if (hasGetUserInfo) {
                let user = store.getters.userInfo
                if (user.Phone && user.IsValid) {
                    next()
                    NProgress.done()
                } else {
                    const isAvailable = new Date(user?.EndTime).getTime() > new Date().getTime()
                    if (to.name == 'phone' || to.name == 'infomation' || to.name == 'home') {
                        next()
                        NProgress.done()
                        // return
                    } else if (!user.Phone) {
                        message.info('请先绑定手机号码！')
                        next(`/phone?redirect=${encodeURIComponent(to.fullPath)}`)
                        NProgress.done()
                        // return
                    } else if (!user.IsValid || !isAvailable) {
                        next('/')
                        store.commit('user/setIsValid', true)
                        NProgress.done()
                    } else {
                        next()
                        NProgress.done()
                    }

                }
            } else {
                try {
                    // 用户登录后获取用户信息
                    let user = await store.dispatch('user/getUserInfo')
                    if (user.Phone || to.name == 'phone' || to.name == 'infomation' || to.name == 'home') {
                        next()
                        NProgress.done()
                    } else {
                        message.info('请先绑定手机号码！')
                        next(`/phone?redirect=${encodeURIComponent(to.fullPath)}`)
                        NProgress.done()
                    }

                } catch (error) {
                    // remove token and go to login page to re-login
                    await store.commit('user/removeUser')
                    next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
                    NProgress.done()
                }
            }
        }
    } else {
        // has no token、
        const { code, state } = to.query
        if (code) {
            await AsyncGetWxUserInfo({ code, state }).then(res => {
                const { data } = res
                store.commit('user/setToken', data)
                store.dispatch('user/getUserInfo').then(res => {
                    if (res.Phone) {
                        next('/')
                    } else {
                        next(`/phone?redirect=${encodeURIComponent(to.path)}`)
                    }

                })
            }).catch(err => {
                console.log(err);
                next()
            })
        } else {
            // if (!to.meta.requiresAuth) {
            //     next()
            // } else {
            //     console.log(to);
            //     next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
            //     NProgress.done()
            // }
            next()
            NProgress.done()
        }

    }
})

router.afterEach((to, from) => {
    // finish progress bar
    // store.dispatch("app/changeTheme", to.meta.topMenuName);
    // store.dispatch("menu/switchModule", to.meta.topMenuName);
    store.commit('user/saveActiveId', '#' + to.path)
    NProgress.done()
})
