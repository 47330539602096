import request from '@/utils/request'
const baseURL = '/API/User'
export const Login = function (data) {// 账号登录
    return request({
        url: "/API/User/Login",
        method: 'POST',
        data:{ json : data },
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const Register = function (data) {// 注册
    return request({
        url: "/API/User/Register",
        method: 'POST',
        data:{ json : data },
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const PhoneLogin = function (data) {// 验证码登录
    return request({
        url: "/API/User/PhoneLogin",
        method: 'POST',
        data:{ json : data },
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const WeiXinLogin = function (data) {// 微信登录
    return request({
        url: "/API/User/WeiXinLogin",
        method: 'get',
        pars:data,
    })
}
export const WeiXinRegister = function (data) {// 微信注册
    return request({
        url: "/API/User/WeiXinRegister",
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const Forget = function (data) {// 忘记密码
    return request({
        url: "/API/User/Forget",
        method: 'POST',
        data:{ json : data },
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

// GET /API/User/AsyncGetWxUserInfo
export const GetTokenByState = function (data) {// 微信二维码 扫码后 轮询获取 token
    return request({
        url: "/API/User/GetTokenByState",
        method: 'GET',
        params: data
    })
}
export const AsyncGetWxUserInfo = function (data) {// 微信二维码 扫码后 轮询获取 token
    return request({
        url: "/API/User/AsyncGetWxUserInfo",
        method: 'GET',
        params: data
    })
}