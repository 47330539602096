import request from '@/utils/request'
const baseURL = '/API/Common'
export const SendSMS = function (data) {// 获取验证码
    return request({
        method: 'post',
        url: '/API/Common/SendSMS',
        data
    })
}

export const GetCardType = function (data) {// 获取卡片类型
    return request({
        method: 'GET',
        url: '/API/Common/GetCardType',
        parmas: data,
    })
}

export const GetCarGetLeveldType = function (data) {// 获取学习程度
    return request({
        method: 'GET',
        url: '/API/Common/GetBaseList',
        params: {
            type: 10010,
            columnId: 10010
        }
    })
}


export const GetSubjectList = function (data) {// 获取考试科目
    return request({
        method: 'GET',
        url: '/API/Common/GetSubjectList',
    })
}
export const GetLibararyCate = function (data) {// 获取图书库类别
    return request({
        method: 'GET',
        url: '/API/Common/GetDataList',
        params: { Columnid: 10012, type: 10012,pageSize:99999 },
    })
}
// export const GetLibararyList = function (data) {// 获取图书列表
//     return request({
//         method: 'GET',
//         url: '/API/Common/GetDataList',
//         params: Object.assign({ Columnid: 10011}, data),
//     })
// }
// export const GetDataById = function (data) {// 获取图书详情
//     return request({
//         method: 'GET',
//         url: '/API/Common/GetDataById',
//         params: Object.assign({ columnId: 10011}, data),
//     })
// }
export const getOSSUpload = function (data) {// OSS上传，获取签名
    return request({
        method: 'post',
        url: '/API/Common/OSSUpload',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        params: data
    })
}
export const GetDataList = function (data) {// 获取指定栏目数据列表
    return request({
        method: 'get',
        url: '/API/Common/GetDataList',
        params: data
    })
}
export const GetColumnByIds = function (data) {// 获取指定栏目信息
    return request({
        method: 'get',
        url: '/API/Common/GetColumnByIds',
        params: data
    })
}
export const GetOptionList = function (data) {// 根据字段id 获取 选项字段的选项
    return request({
        method: 'get',
        url: "/API/Common/GetOptionList",
        params: data
    })
}

export const SaveQuestion = function (config) {// 网站问题反馈
    const data = {
        json:config
    }
    return request({
        url: "/API/Common/SaveQuestion",
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const GetSingleDataById = function (data) {// 根据字段id 获取 详情
    return request({
        method: 'get',
        url: "/API/Common/GetDataById",
        params: data
    })
}


// 获取验证码
export const ImageCode = function (data) {// 根据字段id 获取 详情
    return request({
        method: 'get',
        url: "API/Common/ImageCode",
        params: data
    })
}