
const state = {
    downloadType: [
        {
            value: 0,
            name: 'PDF'
        },
        {
            value: 1,
            name: 'WORD'
        },
        {
            value: 2,
            name: 'HTML'
        }
    ],
    _ossUrl: "https://xuexi-001.oss-cn-beijing.aliyuncs.com",
    navList: [],
    siteInfo: {}
}
const mutations = {
    SET_NAVLIST: (state, list) => {
        state.navList = list
    },
    SET_SITEINFO: (state, data) => {
        state.siteInfo = data
    }
}
export default {
    namespaced: true,
    state,
    mutations
}
