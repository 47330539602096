<template>
    <div id="app" :class="$route.name">
        <Header
            :activeId="0"
            :data="data"
            v-if="!noHead.includes($route.name)"
        />
        <section>
            <a-config-provider :locale="zh_CN">
                <transition name="fade-transform" mode="out-in">
                    <router-view :key="key" />
                </transition>
            </a-config-provider>
            <a-back-top />
        </section>
        <Footer
            :data="data"
            :links="links"
            v-if="!noHead.includes($route.name)"
        />
        <!-- <transition name="fade-transform" mode="out-in">
            <div class="app-loading" key="app-loading" v-if="loading">
                <svg-icon icon-class="spin"></svg-icon>
            </div>
        </transition> -->
    </div>
</template>
<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import Header from '@/components/header/header.vue';
import Footer from '@/components/footer/footer.vue';
import { GetDataList } from '@/api/common';
import Umeng from '@/mixins/Umeng';
import $ from 'jquery';
export default {
    components: {
        Header,
        Footer,
    },
    // mixins: [Umeng],
    data() {
        return {
            zh_CN,
            data: {},
            links: [],
            loading: false,
            noHead: ['login', 'ForgetPw', 'library-info'],
        };
    },
    computed: {
        key() {
            return this.$route.path;
        },
    },
    watch: {
        $route: function (to, from) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            // if (window._hmt) {
            //     let location = window.location;
            //     let contentUrl = location.pathname + location.hash;
            //     window._hmt.push(['_trackPageview', contentUrl]);
            // }
            var nopicpath = './images/nopic.svg'; //无图
            $.each($('img'), function (i, n) {
                $(n).on('error', function () {
                    n.src = nopicpath;
                });
                n.src = n.src;
            });
        },
    },
    methods: {
        async getInfo() {
            this.loading = true;
            await GetDataList({ columnId: 10031 })
                .then(res => {
                    this.data = res.data[0];
                    this.$store.commit('app/SET_SITEINFO', this.data);
                    if (this.data.IsOpen && this.data.ServiceId) {
                        this.$nextTick(() => {
                            // window._hmt = window._hmt || [];
                            // var hm = document.createElement('script');
                            // hm.src =
                            //     'https://hm.baidu.com/hm.js?' +
                            //     this.data.ServiceId;
                            // var s = document.getElementsByTagName('script')[0];
                            // s.parentNode.insertBefore(hm, s);
                            var nopicpath = './images/nopic.svg'; //无图
                            $.each($('img'), function (i, n) {
                                $(n).on('error', function () {
                                    n.src = nopicpath;
                                });
                                n.src = n.src;
                            });
                        });
                    }
                })
                .catch(err => err);
            await GetDataList({ columnId: 10021 })
                .then(res => {
                    this.links = res.data;
                })
                .catch(err => err);
            await GetDataList({ columnId: 10038 }).then(res => {
                const { data } = res;
                this.$store.commit('model/SET_MODELTYPE', data);
            });
            await GetDataList({ columnId: 10037 }).then(res => {
                const { data } = res;
                console.log(data);
                this.$store.commit('app/SET_NAVLIST', data);
            });
            this.loading = false;
        },
    },
    created() {
        this.getInfo();
    },
};
</script>
<style lang="less">
// @import "assets/css/common.less";
#app {
    flex: 1;
    display: flex;
    flex-direction: column;
}

section {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.app-loading {
    position: fixed;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 100px;
    align-items: center;
    justify-content: center;
    z-index: 22;
}
</style>
