<template>
    <div>
        <div class="qrcode_login">
            <div class="img" >
                <div id="wxQrCode"></div>
                <wxlogin
                    v-if="state"
                    appid="wxc29249de0871ca61"
                    :scope="'snsapi_login'"
                    :theme="'black'"
                    self_redirect="true"
                    :redirect_uri="encodeURIComponent(self_redirect)"
                    rel="external nofollow"
                    :state="state"
                />
                <!-- <iframe v-if="state" :src="url" frameborder="0"></iframe> -->
            </div>
            <div class="form-footer">
                <!-- <span class="login_span underline"> 请使用微信“扫一扫” </span> -->
            </div>
        </div>
    </div>
</template>
<script>

import wxlogin from "@/components/vue-wxlogin";
import { GetTokenByState } from '@/api/login'
export default {
    components:{
        wxlogin
    },
    data() {
        return {
            // url: 'https://open.weixin.qq.com/connect/qrconnect?appid=wxc29249de0871ca61&redirect_uri=http://ymcs.top:826/User/&response_type=code&scope=snsapi_login&state=',
            state:'',
            timer:null,
            self_redirect:window.configObj.wechatUrl
        }
    },
    computed:{
        url(){
            return window.configObj.wechatUrl + this.state
        }
    },
    mouted: {
 
    },
    methods: {
        getUrl(){
            var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
            this.state = guid
        },
        setTimer(){
            this.timer = setInterval(() => {
                var that = this
                GetTokenByState({state:this.state}).then(res => {
                    const { data , code } = res
                    if(code < 0){
                        that.$message.error(res.msg)
                        clearInterval(that.timer)
                        that.timer = null
                        this.stae = ''
                        this.getUrl()
                        this.setTimer()
                        return
                    }
                    if(data){
                        console.log(data);
                        that.$store.commit('user/setToken',data)
                        that.$emit('login')
                        that.$store.commit('user/saveLoginDialog', false)
                        clearInterval(that.timer)
                        that.timer = null
                        return
                    }
                }).catch(err => {
                    console.log(err);
                    clearInterval(that.timer)
                    that.timer = null
                    this.stae = ''
                    this.getUrl()
                    this.setTimer()
                })
            },1000)
        }
    },
    created(){
        this.getUrl()
    },
    mounted(){
        this.setTimer()
    },
    beforeDestroy(){
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>
<style lang="less" scoped>
@import '~@/assets/css/login.less';
iframe{
    height: 400PX;
}
</style>