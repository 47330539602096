import request from '@/utils/request'
const baseURL = '/API/Member'
export const GetUserInfo = function (data) {// 网站问题反馈
    return request({
        url: "/API/Member/GetMemberInfo",
        method: 'GET',
    })
}
export const GetUserMemberInfo = function (data) {// 网站问题反馈
    return request({
        url: "/API/Member/GetUserMemberInfo",
        method: 'GET',
    })
}
export const UpdateMemberInfo = function (data) {// 网站问题反馈
    return request({
        url: "/API/Member/UpdateMemberInfo",
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const UpdateMemberPassword = function (data) {// 网站问题反馈
    return request({
        url: "/API/Member/UpdateMemberPassword",
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const GetMyQuestionList = function (data) {//获取我的网站问题反馈列表
    return request({
        url: "/API/Member/GetMyQuestionList",
        method: 'GET',
        params: data
    })
}

export const GetMyCardFeedbackList = function (data) {//获取我的卡片反馈列表
    return request({
        url: "/API/Member/GetMyCardFeedbackList",
        method: 'GET',
        params: data
    })
}

export const GetUserNoticeList = function (data) {//获取我的卡片反馈列表
    return request({
        url: "/API/Member/GetUserNoticeList",
        method: 'GET',
        params: data
    })
}
export const UpdatePhone = function (data) {// 网站问题反馈
    return request({
        url: "/API/Member/UpdatePhone",
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const UnbindWeiXin = function (data) {// 网站问题反馈
    return request({
        url: "/API/Member/UnbindWeiXin",
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const GetBindByState = function (data) {// 网站问题反馈
    return request({
        url: "/API/Member/GetBindByState",
        method: 'GET',
        params: data
    })
}



export const GetMyPostList = function (data) {//获取我的发帖
    return request({
        url: "/API/Member/GetMyPostList",
        method: 'GET',
        params: data
    })
}

export const GetMyCommentList = function (data) {//获取我的评论
    return request({
        url: "/API/Member/GetMyCommentList",
        method: 'GET',
        params: data
    })
}


export const GetMyNoteList = function (data) {//获取我的评论
    return request({
        url: "/API/Member/GetMyNoteList",
        method: 'GET',
        params: data
    })
}



export const LogOff = function (data) {//注销账号
    return request({
        url: "/API/Member/LogOff",
        method: 'GET',
        params: data
    })
}



