<template>
    <a-modal
        v-model="visable"
        centered
        :footer="null"
        class="verify"
        :closable="false"
        :maskStyle="{ opacity: '0.3' }"
        :destroyOnClose="true"
    >
        <slide-verify
            slider-text="向右滑动填充拼图"
            @success="onSuccess"
            @fail="onFail"
            :imgs="imgs"
            ref="slideblock"
            :accuracy="10"
        ></slide-verify>
    </a-modal>
</template>
<script>
import img0 from '@/assets/images/img.jpg';
import img1 from '@/assets/images/img1.jpg';
import img2 from '@/assets/images/img2.jpg';
import img3 from '@/assets/images/img3.jpg';
export default {
    props:['showVerify'],
    data(){
        return{
            visable:false,
            imgs: [
                img0,
                img1,
                img2,
                img3,
            ],
        }
    },
    watch:{
        showVerify(val){
            this.visable = val
        }
    },
    methods:{
        onSuccess(){
            this.$emit('success')
        },
        onFail(){
            this.$emit('fail')
        }
    }
}
</script>