<template>
    <a-modal v-model="isRouter ? isRouter : loginDialog" :footer="null" :width="1000" class="loginDialog"
        @cancel="closeLogin" :destroyOnClose="true" :closable="!isRouter">
        <!-- 扫码登录 -->
        <div class="qrcode-btn" v-if="curLoginNav !== 2">
            <a-tooltip placement="rightTop" visible>
                <template slot="title"><span>扫码登录</span></template>
                <img @click="swicthLogin(2)" src="@/assets/images/login-qrcode.png" />
            </a-tooltip>

        </div>
        <div class="qrcode-btn" v-else>
            <a-tooltip placement="rightTop" visible>
                <template slot="title"><span>账号密码登录</span></template>
                <img @click="swicthLogin(0)" src="@/assets/images/login-account.png" />
            </a-tooltip>

        </div>
        <!-- 登录标题 -->
        <template v-for="item in loginNav">
            <div class="title" :key="item.id" v-show="item.id == curLoginNav">
                <span>{{ item.title }}</span>
            </div>
        </template>
        <div class="dialog-container">
            <!-- 账号密码登录 -->
            <Acount v-show="curLoginNav == 0" @change="swicthLogin" @login="getMemberInfo" :privacy="privacy" v-model="isReadProvicy"/>
            <!-- 验证码登录 -->
            <Verification v-show="curLoginNav == 1" @change="swicthLogin" @login="getMemberInfo" :privacy="privacy" v-model="isReadProvicy"/>
            <!-- 扫码登录 -->
            <Scan v-if="curLoginNav == 2" @change="swicthLogin" @login="getMemberInfo" />
            <!-- 注册 -->
            <Register v-show="curLoginNav == 3" @change="swicthLogin" :privacy="privacy"  v-model="isReadProvicy"/>
            <!-- 忘记密码 -->
            <ForgetPw v-show="curLoginNav == 4" @change="changePwSuccess" />
        </div>
    </a-modal>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Base64 } from "js-base64";
import Acount from "./acount";
import Verification from "./verification";
import Scan from "./scan";
import Register from "./register";
import ForgetPw from "./ForgetPw";
import {GetSingleDataById } from '@/api/common'
export default {
    props: ["isRouter"],
    components: {
        Acount,
        Verification,
        Scan,
        Register,
        ForgetPw,
    },
    data() {
        return {
            type: "login",
            // 标题
            loginNav: [
                {
                    id: 0,
                    title: "欢迎登录",
                },
                {
                    id: 1,
                    title: "验证码登录",
                },
                {
                    id: 2,
                    title: "扫码登录",
                },
                {
                    id: 3,
                    title: "账号注册",
                },
                {
                    id: 4,
                    title: "忘记密码",
                },
            ],
            // 选中标题
            curLoginNav: 0,
            redirect: "",
            otherQuery: "",
            privacy:'',
            isReadProvicy:false
        };
    },
    computed: {
        loginDialog: {
            get() {
                return this.$store.state.user.loginDialog;
            },
            set(newVal) {
                this.$store.commit("user/saveLoginDialog", newVal);
            },
        },
    },
    watch: {
        $route: {
            handler: function (route) {
                const query = route.query;
                if (query) {
                    this.redirect = query.redirect;
                    this.otherQuery = this.getOtherQuery(query);
                }
            },
            immediate: true,
        },
    },
    methods: {
        closeLogin() {
            this.$store.commit("user/saveLoginDialog", false);
        },
        // 切换登录方式
        swicthLogin(type) {
            if(type == 2 && !this.isReadProvicy) return this.$message.info('请阅读并同意《文物保护考试服务平台用户隐私政策》');
            this.curLoginNav = type;
        },
        // 登陆成功获取用户信息
        getMemberInfo() {
            this.$store
                .dispatch("user/getUserInfo")
                .then((res) => {
                    this.$router.replace({
                        path: this.redirect || '/',
                        query: this.otherQuery,
                    })
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getOtherQuery(query) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== "redirect") {
                    acc[cur] = query[cur];
                }
                return acc;
            }, {});
        },
        changePwSuccess() {
            this.closeLogin();
            this.curLoginNav = 0;
        },
        async getData() {
            const {  data } = await  GetSingleDataById({
                id:0,
                columnId:10040
            })
            this.privacy = data?.Content || ""
        }
    },
    mounted() { 
        this.getData()
    },
};
</script>
<style scoped lang="less">
/deep/.ant-modal {
    width: 1000px !important;
}

.loginDialog {
    /deep/.ant-modal-body {
        padding: 60px 200px 50px;
        min-height: 610px;
        position: relative;
    }

    /deep/.ant-form-item-children {
        display: flex;
    }
}

.title {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 45px;

    span {
        padding-bottom: 26px;
        line-height: 1;
        position: relative;
        font-size: 32px;
        color: rgb(51, 51, 51);

        &::before {
            content: "";
            width: 40px;
            height: 5px;
            background-color: #00ffc0;
            border-radius: 2px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
        }
    }
}

.qrcode-btn {
    width: 80px;
    position: absolute;
    left: 5px;
    top: 5px;

    img {
        max-width: 100%;
        width: 100%;
        display: block;
        cursor: pointer;
    }

    &::before {
        content: "";
        width: 0;
        height: 0;
        border: (sqrt(80 * 80 * 2) / 2px) solid transparent;
        position: absolute;
        right: 0;
        top: 0;
        border-top-color: #fff;
        transform-origin: right top;
        transform: rotateZ(-45deg);
        z-index: 2;
    }
}

@media screen and (min-width: 1800px
    /*no*/
) and(max-height:800px

    /*no*/
) {
    /deep/.ant-modal-content {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 1024px

    /*no*/
) {
    /deep/.ant-modal-content {
        transform: scale(1);
    }

    .loginDialog {
        /deep/.ant-modal-body {
            padding: 60px 60px 50px;
            min-height: 710px;
            position: relative;
        }

        /deep/.ant-form-item-children {
            display: flex;
        }
    }

    .qrcode-btn {
        display: none;
    }
}
</style>
