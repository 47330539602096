import { getToken, setToken, removeToken } from '@/utils/auth.js'
import { Base64 } from 'js-base64'
import { Login } from '@/api/login'
import { GetUserInfo, GetUserMemberInfo, LogOff } from '@/api/user'
import { message } from 'ant-design-vue'
import { resetRouter } from '@/router/index.js';
// export default {
const state = {
	userInfo: {
		LoginName: '',
		Id: '',
		Phone: ''
	},
	token: getToken(),
	loginDialog: false,
	navActiveId: null,
	IsValidDialog: false
}
const mutations = {
	setToken(state, token) {
		if (token) {
			//存储到vuex中
			state.token = token;
			//token持久化存储
			setToken(token);
		}
	},
	removeUser(state) {
		state.userInfo = {
			LoginName: '',
		}
		state.token = '';
		removeToken();
	},
	saveUserInfo(state, payload) {
		state.userInfo = payload
	},
	saveAvator(state, payload) {
		state.userInfo.avatar = payload
	},
	saveCartNum(state, payload) {
		state.cartNum = payload
	},
	saveLoginDialog(state, payload) {
		state.loginDialog = payload
	},
	saveActiveId(state, payload) {
		state.navActiveId = payload
	},
	setIsValid(state, payload) {
		state.IsValidDialog = payload
	}
}
const actions = {
	saveUserInfoAction({ commit }, payload) {
		commit('saveUserInfo', payload)
	},
	saveAvatorAction({ commit }, payload) {
		commit('saveAvator', payload)
	},
	saveCartNumAction({ commit }, payload) {
		commit('saveCartNum', payload)
	},
	saveActiveIdAction({ commit }, payload) {
		commit('saveActiveId', payload)
	},
	// 登录
	login({ commit }, userInfo) {
		const { loginName, password, imageCode, requestId } = userInfo;
		let loginData = Base64.encode(JSON.stringify({ loginName, password, imageCode, requestId }))
		return new Promise((resolve, reject) => {
			Login(loginData).then(response => {
				const { data } = response
				commit('setToken', data)
				resolve(response.msg)
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 用户登录后获取用户信息
	getUserInfo({ commit }) {
		return new Promise((resolve, reject) => {
			GetUserMemberInfo().then(response => {
				if (response.code === 401) {
					commit('removeUser', '')
					resolve(response.data)
				} else {
					commit('saveUserInfo', response.data)
					resolve(response.data)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 退出登录
	logout({ commit, state }) {
		return new Promise((resolve, reject) => {
			commit('removeUser', '')
			resolve()
		})
	},
	// 注销
	logOff({ commit, dispatch, state }) {
		let confirmLoading = false

		this._vm.$confirm({
			title: '注销账号',
			content: '是否确认要注销账号，注销后账号信息将无法恢复！',
			confirmLoading: confirmLoading,
			onOk() {
				confirmLoading = true
				return new Promise(async (resolve, reject) => {
					LogOff().then(res => {
						message.success(res.msg)
						confirmLoading = false
						dispatch('logout')
						resetRouter()
						location.replace('/')
					})
				})
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}
}
// }

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
