<template>
    <div>
        <a-spin :spinning="spinning">
            <a-form-model :model="acountLogin" :rules="accountRules" ref="acountForm">
                <a-form-model-item prop="loginName">
                    <div class="input">
                        <svg-icon icon-class="icon_acount" class="icon"></svg-icon>
                        <a-input v-model="acountLogin.loginName" placeholder="请输入您的账号" @keyup.enter="accountLogin" />
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="password">
                    <div class="input">
                        <svg-icon icon-class="icon_password" class="icon"></svg-icon>
                        <a-input v-model="acountLogin.password" placeholder="请输入您的密码" type="password"
                            @keyup.enter="accountLogin" />
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="imageCode">
                    <div class="input">
                        <svg-icon icon-class="icon_ver" class="icon"></svg-icon>
                        <a-input v-model="acountLogin.imageCode" placeholder="请输入验证码" :maxLength="6"
                            @keyup.enter="accountLogin" />
                    </div>
                    <div class="imageCode" title="重新获取" @click="getImageCode">
                        <img :src="'data:image/jpg;base64,' + codeImage" alt="" />
                    </div>
                </a-form-model-item>
                <div class="forget-pw">
                    <span class="login_span" @click="swicthLogin(4)"> 忘记密码了？ </span>
                    <span class="login_span underline" @click="swicthLogin(1)">
                        验证码登录
                    </span>
                </div>
                <div class="forget-pw">
                    <span class="login_span">
                        <a-checkbox v-model="isReadProvicy"> </a-checkbox>
                        <span style="margin-left: 10px;"></span>
                        <span @click.stop="openPrivady">请阅读并同意《文物保护考试服务平台用户隐私政策》</span>
                    </span>
                    
                </div>
                <a-form-model-item class="login">
                    <a-button class="login_btn" @click="accountLogin"> 登 录 </a-button>
                </a-form-model-item>
                <div class="form-footer">
                    <span class="login_span underline" @click="swicthLogin(3)">
                        没有账号，去注册
                    </span>
                </div>
            </a-form-model>
        </a-spin>
        <a-modal v-model="privacyShow" :width="800" title="《文物保护考试服务平台用户隐私政策》" @ok="handleOk">
            <div v-html="privacy"></div>
        </a-modal>
        <!-- <Verify :showVerify="showVerify" @success="onSuccess" @fail="onFail" /> -->
    </div>
</template>
<script>
import Verify from "@/components/common/Verify";
import { ImageCode } from "@/api/common";
import { message } from "ant-design-vue";
export default {
    props: ["curLoginNav",'privacy','value'],
    components: {
        Verify,
    },
    data() {
        return {
            // 账号登录
            acountLogin: {
                loginName: "",
                password: "",
                imageCode: "",
                requestId: "",
            },
            codeImage: "",
            accountRules: {
                loginName: [{ required: true, message: "请输入账号", trigger: "blur" }],
                password: [{ required: true, message: "请输入密码", trigger: "blur" }],
                imageCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
            },
            spinning: false,
            // 图形验证
            showVerify: false,
            privacyShow:false
        };
    },
    computed:{
        isReadProvicy:{
            get(){
                return this.value
            },
            set(newVal){
                this.$emit('input',newVal)
            }
        }
    },
    methods: {
        // 图形验证失败
        onFail() {
            this.$message.error("请点击鼠标左键，滑动至缺口!");
            // this.$refs.slideblock.reset()
        },
        // 图形验证成功
        onSuccess() {
            setTimeout(() => {
                // this.showVerify = false
                this.spinning = true;
                this.$store
                    .dispatch("user/login", this.acountLogin)
                    .then((res) => {
                        console.log(res);
                        this.$store.commit("user/saveLoginDialog", false);
                        this.$message.success(res);
                        this.$emit("login");
                        this.spinning = false;
                    })
                    .catch((err) => {
                        this.spinning = false;
                        console.log(err);
                    });
            }, 0);
        },
        // 请点击鼠标左键，滑动至缺口!
        accountLogin() {
            if(!this.isReadProvicy)  return  this.$message.info('请阅读并同意《文物保护考试服务平台用户隐私政策》');
            this.$refs.acountForm.validate((valid) => {
                if (valid) {
                    this.onSuccess();
                } else {
                    return false;
                }
            });
        },
        // 切换登录方式
        swicthLogin(type) {
            this.$emit("change", type);
        },
        // 获取验证码
        async getImageCode() {
            const { data } = await ImageCode();
            this.codeImage = data.Base64;
            this.acountLogin.requestId = data.RequestId;
        },
        openPrivady(){
            this.privacyShow = true
        },
        handleOk(){
            this.isReadProvicy = true
            this.privacyShow = false
        }
    },
    created() {
        this.getImageCode();
    },
};
</script>
<style scoped lang="less">
@import "~@/assets/css/login.less";

.imageCode {
    align-self: center;
    cursor: pointer;
}
</style>
