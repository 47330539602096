import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: () => import("@/views/Login"),
        name: 'login',
        meta: { title: '登录', ActiveId: null, isNav: false, }
    },
    {
        path: '/forgetpw',
        component: () => import("@/views/Login/ForgetPw"),
        name: 'ForgetPw',
        meta: { title: '忘记密码', ActiveId: null, isNav: false, }
    },
    {
        path: '/',
        component: () => import("@/views/Home/index"),
        breadcrumbName: '首页',
        name: 'home',
        meta: { title: '首页', ActiveId: 0, isNav: true }
    },
    {
        path: '/learning',
        component: () => import("@/views/Learning"),
        name: 'learning',
        redirect: '/learning/list',
        breadcrumbName: '学习库',
        meta: { title: '学习库', ActiveId: 2, isNav: true, requiresAuth: false },
        children: [
            {
                path: 'list',
                name: 'learning-list',
                meta: { ActiveId: 2, requiresAuth: false },
                component: () => import("@/views/Learning/components/List"),
            },
            {
                path: 'info/:id?',
                name: 'learning-info',
                meta: { ActiveId: 2, requiresAuth: true },
                component: () => import("@/views/Learning/components/_Info")
            },
            {
                path: 'card/:id?',
                name: 'card-info',
                meta: { ActiveId: 2, requiresAuth: true },
                component: () => import("@/views/Learning/components/_Card")
            },
            {
                path: 'model/:id?',
                name: 'model-info',
                meta: { ActiveId: 2, requiresAuth: true },
                component: () => import("@/views/Learning/components/_Model")
            },
            {
                path: 'compared/:id?',
                name: 'compared-info',
                meta: { title: '分屏查看', ActiveId: 2, requiresAuth: true },
                component: () => import("@/views/Learning/components/_Compared")
            },

        ]
    },
    {
        path: '/practice',
        name: 'practice',
        meta: { title: '练习题库', ActiveId: 3, isNav: true, link: 'http://wbtk.wbsjk.com/' }
    },
    {
        path: '/library',
        component: () => import("@/views/Library"),
        name: 'library',
        redirect: '/library/list',
        breadcrumbName: '参考资料',
        meta: { title: '参考资料', ActiveId: 4, isNav: true, requiresAuth: true },
        children: [
            {
                path: 'list',
                name: 'library-list',
                meta: { ActiveId: 4, requiresAuth: true },
                component: () => import("@/views/Library/components/List"),
            },
            {
                path: 'info/:id?',
                name: 'library-info',
                meta: { ActiveId: 4, requiresAuth: true },
                component: () => import("@/views/Library/components/_Info")
            },
        ]
    },
    {
        path: '/Forum',
        component: () => import("@/views/Forum"),
        name: 'forum',
        redirect: { name: 'forum-list' },
        meta: { title: '考试交流论坛', ActiveId: 1, isNav: true, requiresAuth: true },
        children: [
            {
                path: '/forumlist',
                name: 'forum-list',
                component: () => import("@/views/Forum/components/List"),
                meta: { requiresAuth: true, ActiveId: 1, },
            },
            {
                path: '/foruminfo/:id?',
                name: 'forum-info',
                component: () => import("@/views/Forum/components/_Info"),
                meta: { requiresAuth: true, ActiveId: 1, },
            },
            {
                path: '/publish',
                name: 'publish',
                component: () => import("@/views/Forum/components/Publish"),
                meta: { title: '发布新帖', requiresAuth: true, ActiveId: 1, },
            }
        ]
    },
    {
        path: '/personal',
        component: () => import("@/views/Personal"),
        name: 'personal',
        redirect: { name: 'basic' },
        meta: { title: '个人中心', isNav: false, requiresAuth: true },
        children: [
            {
                path: '/basic',
                name: 'basic',
                breadcrumbName: '基本信息',
                component: () => import("@/views/Personal/Basic"),
                redirect: { name: 'infomation' },
                meta: { title: '基本信息', requiresAuth: true },
                children: [
                    {
                        path: '/infomation',
                        name: 'infomation',
                        breadcrumbName: '个人基本信息',
                        component: () => import("@/views/Personal/Basic/Information"),
                        meta: { title: '个人基本信息', requiresAuth: true },
                    },
                    {
                        path: '/changepw',
                        name: 'changepw',
                        breadcrumbName: '修改密码',
                        component: () => import("@/views/Personal/Basic/ChangePw"),
                        meta: { title: '修改密码', requiresAuth: true },
                    },
                    // {
                    //     path: '/order',
                    //     name: 'order',
                    //     breadcrumbName: '订单记录',
                    //     component: Order,
                    //     meta:{ title: '订单记录' , requiresAuth : true },
                    // },
                    // {
                    //     path: '/bill',
                    //     name: 'bill',
                    //     breadcrumbName: '发票记录',
                    //     component: Bill,
                    //     meta:{ title: '发票记录' , requiresAuth : true },
                    // }
                ]
            },
            {
                path: '/notes',
                name: 'notes',
                breadcrumbName: '我的笔记',
                redirect: { name: 'mynotes' },
                component: () => import("@/views/Personal/Notes"),
                meta: { title: '我的笔记', requiresAuth: true },
                children: [
                    {
                        path: '/mynotes',
                        name: 'mynotes',
                        breadcrumbName: '我的笔记',
                        component: () => import("@/views/Personal/Notes/MyNotes"),
                        meta: { title: '我的笔记', requiresAuth: true },
                    },
                ]
            },
            {
                path: '/behavior',
                name: 'behavior',
                breadcrumbName: '历史记录',
                component: () => import("@/views/Personal/Behavior"),
                redirect: { name: 'browse' },
                meta: { title: '历史记录', requiresAuth: true },
                children: [
                    {
                        path: '/browse',
                        name: 'browse',
                        breadcrumbName: '浏览记录',
                        component: () => import("@/views/Personal/Behavior/Browse"),
                        meta: { title: '浏览记录', requiresAuth: true },
                    },
                    {
                        path: '/collect',
                        name: 'collect',
                        breadcrumbName: '收藏记录',
                        component: () => import("@/views/Personal/Behavior/Collect"),
                        meta: { title: '收藏记录', requiresAuth: true },
                    },
                    {
                        path: '/download',
                        name: 'download',
                        breadcrumbName: '下载记录',
                        component: () => import("@/views/Personal/Behavior/Download"),
                        meta: { title: '下载记录', requiresAuth: true },
                    },
                ]
            },
            // {
            //     path: '/message',
            //     name: 'message',
            //     breadcrumbName: '我的留言',
            //     component: () => import("@/views/Personal/Message"),
            //     redirect: {name: 'Comment'},
            //     meta:{ title: '我的留言' , requiresAuth : true },
            //     children:[
            //         {
            //             path: '/comment',
            //             name: 'comment',
            //             breadcrumbName: '评论',
            //             component: import("@/views/Personal/Message/Comment"),
            //             meta:{ title: '评论' , requiresAuth : true },
            //         },
            //         {
            //             path: '/remark',
            //             name: 'remark',
            //             breadcrumbName: '批注',
            //             component: import("@/views/Personal/Message/Remark"),
            //             meta:{ title: '批注' , requiresAuth : true },
            //         },
            //         {
            //             path: '/notes',
            //             name: 'notes',
            //             breadcrumbName: '笔记',
            //             component: Notes,
            //             meta:{ title: '笔记' , requiresAuth : true },
            //         },
            //     ]
            // },
            {
                path: '/release',
                name: 'release',
                breadcrumbName: '我的发布',
                component: () => import("@/views/Personal/Release"),
                redirect: { name: 'infomation' },
                meta: { title: '我的发布', requiresAuth: true },
                children: [
                    {
                        path: '/post',
                        name: 'post',
                        breadcrumbName: '帖子',
                        component: () => import("@/views/Personal/Release/Post"),
                        meta: { title: '帖子', requiresAuth: true },
                    },
                    {
                        path: '/comment',
                        name: 'comment',
                        breadcrumbName: '模型评论',
                        component: () => import("@/views/Personal/Release/Comment"),
                        meta: { title: '模型评论', requiresAuth: true },
                    },
                    // {
                    //     path: '/manual',
                    //     name: '',
                    //     breadcrumbName: '学习手册',
                    //     component: Manual,
                    //     meta:{ title: '学习手册' , requiresAuth : true },
                    // },
                ]
            },
            {
                path: '/myfeedback',
                name: 'myfeedback',
                breadcrumbName: '我的反馈',
                component: () => import("@/views/Personal/Feedback"),
                redirect: { name: 'sitefeedback' },
                meta: { title: '我的发布', requiresAuth: true },
                children: [
                    {
                        path: '/sitefeedback',
                        name: 'sitefeedback',
                        breadcrumbName: '站点反馈',
                        component: () => import("@/views/Personal/Feedback/Site"),
                        meta: { title: '站点反馈', requiresAuth: true },
                    },
                    {
                        path: '/cardfeedback',
                        name: 'cardfeedback',
                        breadcrumbName: '内容反馈',
                        component: () => import("@/views/Personal/Feedback/Card"),
                        meta: { title: '内容反馈', requiresAuth: true },
                    },
                ]
            },
            {
                path: '/audit',
                name: 'audit',
                breadcrumbName: '待审核',
                component: () => import("@/views/Personal/Audit"),
                redirect: { name: 'commentAudit' },
                meta: { title: '待审核', requiresAuth: true },
                children: [
                    {
                        path: '/commentAudit',
                        name: 'commentAudit',
                        breadcrumbName: '模型评论',
                        component: () => import("@/views/Personal/Audit/Model"),
                        meta: { title: '模型评论', requiresAuth: true },
                    },
                ]
            },
            {
                path: '/letter',
                name: 'letter',
                breadcrumbName: '站内信',
                component: () => import("@/views/Personal/Letter"),
                // redirect: {name: 'infomation'},
                meta: { title: '站内信', requiresAuth: true },
            },
            {
                path: '/letter/:id?',
                name: 'letterinfo',
                breadcrumbName: '站内信',
                component: () => import("@/views/Personal/Letter/Info"),
                meta: { title: '站内信', requiresAuth: true, isNoSide: true, select: 'letter' },
            },
        ]
    },
    {
        path: '/search',
        name: 'search',
        component: () => import("@/views/Search"),
        meta: { title: '高级检索', ActiveId: 0, isNav: false }
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: () => import("@/views/Feedback"),
        meta: { title: '问题处理中心反馈平台', ActiveId: null, isNav: false }
    },
    {
        path: '/auxiliary',
        component: () => import("@/views/Personal"),
        name: 'auxiliary',
        redirect: { name: 'question' },
        meta: { title: '其他', isNav: false, requiresAuth: false },
        children: [
            {
                path: '/question',
                component: () => import("@/views/Auxiliary/Question"),
                name: 'question',
                meta: { title: '常见问题', isNav: false, requiresAuth: false },
            },
            {
                path: '/solution',
                component: () => import("@/views/Auxiliary/Solution"),
                name: 'solution',
                meta: { title: '解决方案', isNav: false, requiresAuth: false },
            },
            {
                path: '/webmanual',
                component: () => import("@/views/Auxiliary/WebManual"),
                name: 'webmanual',
                meta: { title: '网站说明手册', isNav: false, requiresAuth: false },
            },
            {
                path: '/question/:id?',
                component: () => import("@/views/Auxiliary/Info"),
                name: 'questioninfo',
                meta: { title: '常见问题', isNoSide: true, requiresAuth: false, select: 'question' },
            },
            {
                path: '/solution/:id?',
                component: () => import("@/views/Auxiliary/Info"),
                name: 'solutioninfo',
                meta: { title: '解决方案', isNoSide: true, requiresAuth: false, select: 'solution' },
            }
        ]
    },
    {
        path: '/notice/:id?',
        name: 'noticeinfo',
        breadcrumbName: '通知',
        component: () => import("@/views/News/Info"),
        meta: { title: '通知', requiresAuth: false, },
    },
    {
        path: '/phone',
        name: 'phone',
        breadcrumbName: '绑定手机',
        component: () => import("@/views/Phone"),
        meta: { title: '绑定手机', requiresAuth: true, isNav: false }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch(err => err)
}
export function createRouter() {
    return router
}

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // the relevant part
}
export default router

