import Cookies from 'js-cookie'

const tokenKey = 'user-token'

export function getToken () {
  return Cookies.get(tokenKey)
}

export function setToken (token, autoLogin) {
  // 自动登录token有效期7天
  return Cookies.set(tokenKey, token, { expires: autoLogin ? 7 : '' })
}

export function removeToken () {
  return Cookies.remove(tokenKey)
}
