<template>
    <footer class="footer" id="footer">
        <div class="auto-1440">
            <div class="top">
                <div class="links">
                    <h2>友情链接：</h2>
                    <div>
                        <a
                            class="dot"
                            v-for="item in links"
                            :key="item.Id"
                            :href="item.LinkUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {{ item.Title }}
                        </a>
                    </div>
                </div>
                <div class="qrcode">
                    <div class="item">
                        <div class="img">
                            <img
                                :src="data.WXCode"
                                :alt="data.WXCodeName"
                                v-if="data.WXCode"
                            />
                        </div>
                        <span>{{ data.WXCodeName }}</span>
                    </div>
                    <div class="item">
                        <div class="img">
                            <img
                                :src="data.WXCode2"
                                :alt="data.WXCode2Name"
                                v-if="data.WXCode2"
                            />
                        </div>
                        <span>{{ data.WXCode2Name }}</span>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="links">
                    <router-link
                        v-for="item in navLinks"
                        :key="item.id"
                        :to="{ path: item.path }"
                    >
                        {{ item.title }}
                    </router-link>
                    <!-- <a
                        v-for="item in navLinks"
                        :key="item.id"
                        :href="item.path"
                    >
                        {{ item.title }}
                    </a> -->
                </div>
                <div class="copyright">
                    {{ data.Copyright }}
                    <a
                        href="https://beian.miit.gov.cn/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ data.ICP }}
                    </a>
                    <a
                        href="https://beian.miit.gov.cn/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ data.ICPZ }}
                    </a>
                    <a
                        href="http://www.beian.gov.cn/portal/registerSystemInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ data.PublicNetwork }}
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    Copyright: '',
                    ICP: '',
                    ICPZ: '',
                    PublicNetwork: '',
                    WXCode: '',
                    WXCode2: '',
                }
            },
        },
        links: Array,
    },
    data() {
        return {
            loading: true,
            friendLinks: [],
            navLinks: [
                {
                    id: 0,
                    title: '常见问题',
                    path: '/question',
                },
                {
                    id: 1,
                    title: '解决方案',
                    path: '/solution',
                },
                {
                    id: 2,
                    title: '网站说明手册',
                    path: '/webmanual',
                },
                {
                    id: 3,
                    title: '问题处理中心',
                    path: '/feedback',
                },
            ],
        }
    },
}
</script>
<style scoped lang="less">
.footer {
    background-color: #111;
    padding-top: 0.5rem;
}
.top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.102);
    .links {
        flex: 1;
        h2 {
            font-size: 20px;
            line-height: 1;
            color: rgb(255, 255, 255);
            margin-bottom: 15px;
        }
        & > div {
            display: flex;
            flex-wrap: wrap;
            a {
                padding-right: 20px;
                min-width: 25%;
                font-size: 16px;
                line-height: 2;
                color: #888888;
                padding-left: 14px;
                position: relative;
                &:hover {
                    color: #00ffc0;
                }
                &::before {
                    content: '';
                    width: 5px;
                    height: 5px;
                    background-color: #888;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
    }
    .qrcode {
        flex-shrink: 0;
        display: flex;
        .item {
            margin-left: 20px;
            text-align: center;
        }
        .img {
            width: 120px;
            padding: 4px;
            background-color: #fff;
            display: block;
            min-width: 85px; /* no */
            img {
                display: block;
            }
        }
        span {
            font-size: 16px;
            color: rgb(136, 136, 136);
            line-height: 1.25;
            margin-top: 10px;
            display: block;
        }
    }
}
.bottom {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 30px 0;
    .links {
        display: flex;
        a {
            color: rgb(85, 85, 85);
            position: relative;
            padding: 0 10px;
            &:first-of-type {
                padding-left: 0;
            }
            &:not(:last-of-type)::after {
                content: '';
                width: 1px; /*no*/
                height: 10px; /*no*/
                background-color: rgb(255, 255, 255);
                opacity: 0.102;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &:hover {
                color: #00ffc0;
            }
        }
    }
    .copyright {
        color: rgb(85, 85, 85);
        display: flex;
        a {
            color: rgb(85, 85, 85);
            padding: 0 10px;
            position: relative;
            &:hover {
                color: #00ffc0;
            }
            &:last-of-type {
                padding-right: 0;
            }
            &:not(:last-of-type)::after {
                content: '';
                width: 1px; /*no*/
                height: 10px; /*no*/
                background-color: rgb(255, 255, 255);
                opacity: 0.102;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
</style>
