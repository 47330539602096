import axios from 'axios'
import { message } from 'ant-design-vue'
import store from '@/store'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : window.configObj.baseURL,
  // process.env.NODE_ENV === 'development'?'/API/Card':window.configObj.baseURL + '/API/Card'
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 60000, // request timeout=
  // baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : window.configObj.baseURL,
})

// request interceptor
service.interceptors.request.use(
  config => {
    // console.log('打印 request')
    // console.log(config)
    // do something before request is sent
    config.headers.post['Content-Type'] = 'application/json'
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.Authorization = store.getters.token
    }
    // if (store.getters.bizCode) {
    //   config.headers['bizCode'] = getBizCode()
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // console.log('打印 response')
    // console.log(response.data)
    // TODO 上传文件没有响应体，所以先不走错误流程
    if (!res) return
    // if the custom code is not 0, it is judged as an error.
    if (!res.success) {
      message.error(res.msg || 'error')

      if (res.code === 401 && !location.href.includes('/login')) {
        store.commit('user/removeUser')
        if (store.getters.navActiveId == 0) {
          router.push({ path: '/' })
        } else {
          router.push(`/login?redirect=${encodeURIComponent(router?.history?.current?.fullPath)}`)
          // router.push({ path: '/login', query: { redirect: encodeURIComponent(router?.history?.current?.fullPath) } })
        }
      }

      return Promise.reject(res.msg || 'error')
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
