import { GetLibararyCate } from '@/api/common'
const state =  {
    cate: [],
}
const mutations = {
    SET_CATE: (state, cate) => {
        state.cate = cate
    },
}
const actions = {
    getCate({ commit, state }){
        return new Promise((resolve, reject) => {
            if(state.cate.length > 0){
                resolve(state.cate)
            }else{
                console.log(1);
                GetLibararyCate().then(res => {
                    const { data } = res
                    commit('SET_CATE',data)
                    resolve(state.cate)
                }).catch(err => {
                    reject(err)
                })
            }
        })
        
    }
} 

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
